import { ref, computed } from "vue";

const drawer = ref(false);
const isForcedLoading = ref(false);
const documentIsLoading = ref(document.readyState === 'loading');
const currentTheme = ref(localStorage.getItem("theme") || "light");

const updateDocumentLoadingState = () => {
  documentIsLoading.value = document.readyState === 'loading';
};

const isLoading = computed(() => isForcedLoading.value || documentIsLoading.value);

export const useApp = () => {
  const setTheme = (themeName: string) => {
    currentTheme.value = themeName;
    localStorage.setItem("theme", themeName);
  };
  
  const initializeTheme = () => {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      setTheme(storedTheme);
    } else {
      setTheme(
        window.matchMedia("(prefers-color-scheme: dark)").matches
          ? "dark"
          : "light"
      );
    }
  };


  const isDark = computed(() => currentTheme.value === "dark");

  const changeTheme = () => {
    setTheme(isDark.value ? "light" : "dark");
  };

  const getThemeInverse = () => {
    return isDark.value ? "light" : "dark";
  };

  const getTheme = () => currentTheme.value;

  const toggleDrawer = () => {
    drawer.value = !drawer.value;
  };

  const setLoading = (value: boolean) => {
    isForcedLoading.value = value;
  };


  document.addEventListener('readystatechange', updateDocumentLoadingState);
  initializeTheme();

  return {
    drawer,
    isLoading,
    toggleDrawer,
    setLoading,
    isDark,
    changeTheme,
    getTheme,
    getThemeInverse,
    setTheme,
    currentTheme,
  };
};
