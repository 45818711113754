// Composables
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { useCrudRoutes } from "./crudRoutes";
import { useLogin } from "@/composables/useLogin";
const { isUserAdmin, isUserRegistered } = useLogin();
const routesV2: RouteRecordRaw[] = [
  {
    path: '/v2',
    component: () => import('@/layouts/base/LayoutV2.vue'),
    children: [
      {
        path: '',
        name: 'HomeV2',
        component: () => import(/* webpackChunkName: "home-v2" */ '@/apps/home/HomeV2.vue'),
      },

    ],
  },
];

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('@/layouts/base/Default.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home-central" */ '@/apps/home/Home.vue'),
      },
      {
        path: 'financiamento',
        name: 'Financiamento',
        meta: { requiresAuth: true },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "financiamento" */ '@/apps/financiamento/FinanciamentoForm.vue'),
      },
      {
        path: 'financiamento2',
        name: 'Financiamento2',
        meta: { requiresAuth: true },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "financiamento2" */ '@/apps/financiamento/Financiamento2Form.vue'),
      },
      {
        path: 'geradorpanfleto',
        name: 'GeradorPanfleto',
        meta: { requiresAuth: true },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "geradorPanfleto" */ '@/apps/consorcio/GeradorPanfletoForm.vue'),
      },
      {
        path: 'imagempost',
        name: 'ImagemPost',
        meta: { requiresAuth: true },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "imagemPost" */ '@/apps/consorcio/ImagemPostForm.vue'),
      },

      {
        path: 'premiacao',
        name: 'Premiacao',
        meta: { requiresAuth: true },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "premiacaoForm" */ '@/apps/premiacao/PremiacaoForm.vue'),
      },
      {
        path: 'panfleto',
        name: 'Panfleto',
        meta: { requiresAuth: true },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "panfleto" */ '@/apps/consorcio/PanfletoForm.vue'),
      },
      {
        path: 'impressora',
        name: 'Impressora',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "impressora" */ '@/apps/configuracao/PrinterForm.vue'),
      },

      {
        path: 'estoque',
        name: 'Estoque',
        meta: { requiresAuth: true },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "estoque" */ '@/apps/estoque/EstoqueForm.vue'),
      },
      {
        path: 'fipe',
        name: 'Fipe',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "fipe" */ '@/apps/seminovo/FipeForm.vue'),
      },
      {
        path: 'registrarseminovo',
        name: 'RegisrarSeminovo',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "registrarseminovo" */ '@/apps/seminovo/RegistrarSeminovoForm.vue'),
      },
      
      {
        path: 'seminovos',
        name: 'Seminovos',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "seminovos" */ '@/apps/seminovo/ListarSeminovosForm.vue'),
      },
      {
        path: 'imagemparabens',
        name: 'ImagemParabens',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "imagemparabens" */ '@/apps/imagens/ImagemParabensForm.vue'),
      },
      {
        path: 'imagempecas',
        name: 'ImagemPecas',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "imagempecas" */ '@/apps/imagens/ImagemPecasForm.vue'),
      },
      {
        path: 'teste',
        name: 'Teste',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "teste" */ '@/apps/teste/Teste.vue'),
      },
      {
        path: 'teste2',
        name: 'Teste2',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "teste2" */ '@/apps/teste/Teste2.vue'),
      },
      {
        path: 'login',
        name: 'Login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "login" */ '@/apps/user/Login.vue'),
      },
      {
        path: 'logout',
        name: 'Logout',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "logout" */ '@/apps/user/Logout.vue'),
      },
      {
        path: 'unauthorized',
        name: 'Unauthorized',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "logout" */ '@/apps/user/Unauthorized.vue'),
      },
      {
        path: 'usuarios',
        name: 'Usuarios',
        meta: { requiresAuth: true, requiresAdmin: true },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Usuarios" */ '@/apps/user/Admin.vue'),
      },
      {
        path: 'pps',
        name: 'PPS',
        meta: { requiresAuth: true, requiresAdmin: true },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "PPS" */ '@/apps/pps/PpsForm.vue'),
      },
      {
        path: 'propostas',
        name: 'Propostas',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Propostas" */ '@/apps/proposta/PropostasForm.vue'),
      },
      {
        path: 'proposta',
        name: 'Proposta',
        component: () => import(/* webpackChunkName: "Proposta" */ '@/apps/proposta/PropostaForm.vue'),
      },
      {
        path: 'proposta2',
        name: 'Proposta2',
        meta: { requiresAuth: true, requiresAdmin: false },
        component: () => import(/* webpackChunkName: "Proposta2" */ '@/apps/proposta/PropostaForm2.vue'),
      },
      {
        path: 'plano',
        name: 'FinanciamentoPlano',
        meta: { requiresAuth: true, requiresAdmin: true },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "FinanciamentoPlano" */ '@/apps/financiamento/FinanciamentoPlanoForm.vue'),
      },
      ...useCrudRoutes("Campanha", () => import(/* webpackChunkName: "campanhaList" */'@/apps/campanha/CampanhaList.vue'), () => import(/* webpackChunkName: "campanhaForm" */'@/apps/campanha/CampanhaForm.vue')),

    ],
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('@/layouts/base/Default.vue'),
    meta: { requiresAuth: true, adminOnly: true },
    children: [
    ]
  },
  {
    path: '/tv',
    name: 'TV',
    component: () => import('@/layouts/base/TV.vue'),
    meta: { requiresAuth: false, adminOnly: false },
    children: [
      {
        path: '',
        name: 'TVHome',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "TV" */ '@/apps/tv/CatalogoForm.vue'),
      },
      {
        path: '/tv/:id',
        name: 'TVProduto',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "ProdutoForm" */ '@/apps/tv/ProdutoForm.vue'),
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [...routes, ...routesV2],
});

router.beforeEach(async (to, from, next) => {

  console.log('to', to);
  console.log('user', isUserRegistered.value);
  if (to.meta.requiresAuth) {

    if (isUserRegistered.value) {
      if (to.meta.requiresAdmin && !isUserAdmin) {
        next({ name: 'Unauthorized' }); // Redirecionar para uma tela de acesso não autorizado
      } else {
        next();
      }
    } else {
      next({ name: 'Home' }); // Redirecionar para a tela de login
    }
  } else {
    next();
  }
});

export default router
