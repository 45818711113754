// useLogin
import { ref, onMounted, onUnmounted } from "vue";
import * as auth from "firebase/auth";
import { auth as cfgAuth } from "@/firebaseConfig";
import { useApi } from "./useApi";
import { useMsg } from "@/composables/useMsg";
import { useData } from "@/composables/useData";
import { useMoto } from "@/composables/useMoto";



const { getUsuarioByUid, postUsuario } = useApi();
const { showMessage } = useMsg();
const { loadResources } = useData();
const { initializeMotoData } = useMoto();

let listenerAuthState: (() => void) | null = null;
const firebaseUser = ref<any | null>(null);
const registeredUser = ref<any | null>(null);
const isUserRegistered = ref(false);
const isUserLoggedIn = ref(false);
const isWaitingForResponse = ref(false);
const isUserApproved = ref(false);
const isUserAdmin = ref(false);
const isWaitingVerification = ref(false);

function initializeAuthStateFromLocalStorage() {
  const userData = localStorage.getItem("userData");
  if (userData) {
    const parsedData = JSON.parse(userData);
    firebaseUser.value = parsedData.firebaseUser;
    registeredUser.value = parsedData.registeredUser;
    isUserLoggedIn.value = parsedData.isUserLoggedIn;
    isUserRegistered.value = parsedData.isUserRegistered;
    isUserApproved.value = parsedData.isUserApproved;
    isUserAdmin.value = parsedData.isUserAdmin;
    isWaitingVerification.value = parsedData.isWaitingVerification;
    if (isUserRegistered.value) {
      loadResources(registeredUser.value.id);
      initializeMotoData();
    }
    
  }
}

// Chama a função de inicialização antes de qualquer outro processo
initializeAuthStateFromLocalStorage();

export function useLogin() {
  
  onMounted(() => {

    // Registra o listener para monitorar mudanças na autenticação
    if (!listenerAuthState) {
      console.log("onMounted - registrando listener");
      listenerAuthState = auth.onAuthStateChanged(
        cfgAuth,
        async (firebaseUser: any) => {
          console.log("firebaseUser:", firebaseUser);
          if (firebaseUser) {
            if (!firebaseUser.emailVerified) {
              console.log(
                "Email não verificado. Envie um email de verificação ou avise o usuário."
              );
              isWaitingVerification.value = true; // Define que está aguardando verificação de e-mail
              updateLocalStorage(); // Atualiza o localStorage com o estado mais recente
            } else {
              console.log("Email verificado. Permitir acesso completo ao usuário.");
              isWaitingVerification.value = false; // Usuário verificou o email, permitir acesso
              await updateUser(firebaseUser); // Chama updateUser para buscar e configurar o usuário
            }
          } else {
            resetControlSession();
          }
        }
      );
    }
  });

  onUnmounted(() => {
    if (listenerAuthState) {
      listenerAuthState(); // Desregistrar listener
      listenerAuthState = null; // Limpar a variável de controle
      console.log("onUnmounted - removendo listener");
    }
  });

  const updateLocalStorage = () => {
    localStorage.setItem(
      "userData",
      JSON.stringify({
        firebaseUser: firebaseUser.value,
        registeredUser: registeredUser.value,
        isUserLoggedIn: isUserLoggedIn.value,
        isUserRegistered: isUserRegistered.value,
        isUserApproved: isUserApproved.value,
        isUserAdmin: isUserAdmin.value,
        isWaitingVerification: isWaitingVerification.value,
      })
    );
  };


  
  const updateUser = async (user: any) => {
    if (user) {
      firebaseUser.value = { ...user };
      isUserLoggedIn.value = true;
      await queryRegisteredUser(user.uid);
      updateLocalStorage();
    } else {
      resetControlSession();
    }
  };

  const resetControlSession = () => {
    firebaseUser.value = null;
    registeredUser.value = null;
    isUserRegistered.value = false;
    isWaitingVerification.value = false;
    isWaitingForResponse.value = false;
    isUserLoggedIn.value = false;
    isUserApproved.value = false; 
    isUserAdmin.value = false;  
    localStorage.removeItem('userData'); 
};

  const handleUserRegistered = (usuario: any) => {
    if (usuario && usuario.uid && usuario.nome) {
        isUserApproved.value = usuario.aprovado || false;
        const roles = usuario.permissoes || [];
        isUserAdmin.value = roles.includes("admin");
        registeredUser.value = usuario;
        isUserRegistered.value = true;
        console.log("Usuário registrado:", usuario);
        loadResources(registeredUser.value.id);
        initializeMotoData();
    } else {
        isUserRegistered.value = false;
        isUserApproved.value = false; 
        isUserAdmin.value = false; 
        registeredUser.value = null; 
        console.log("Usuário não registrado ou dados incompletos.");
    }
};

  const queryRegisteredUser = async (uid: string) => {
    isWaitingForResponse.value = true;
    try {
      const usuario = await getUsuarioByUid(uid);
      console.log("response:", usuario);
      handleUserRegistered(usuario);
    } catch (error: any) {
      console.error("Erro ao buscar usuário:", error.message);
      isUserRegistered.value = false;
    } finally {
      isWaitingForResponse.value = false;
    }
  };

  const signInWithGoogle = async () => {
    isWaitingForResponse.value = true;
    const provider = new auth.GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: "select_account",
    });
    try {
      await auth.signInWithPopup(cfgAuth, provider);
    } catch (error: any) {
      showMessage(`Erro ao fazer login com Google`);
    }
    finally {
      isWaitingForResponse.value = false;
    }
  };

  const signInWithFacebook = async () => {
    isWaitingForResponse.value = true;
    const provider = new auth.FacebookAuthProvider();
    provider.addScope('email');
    provider.addScope('public_profile');
    try {
      const result = await auth.signInWithPopup(cfgAuth, provider);
      const user = result.user;
      const credential = auth.FacebookAuthProvider.credentialFromResult(result);
      const accessToken = credential?.accessToken;
  
      console.log('Usuário autenticado:', user);
      console.log('Token de acesso do Facebook:', accessToken);
    } catch (error: any) {
      console.error('Erro ao fazer login com Facebook:', error.message);
      showMessage(`Erro ao fazer login com Facebook`,"error");
    }
    finally {
      isWaitingForResponse.value = false;
    }
  };

  const signInWithEmailAndPassword = async (
    email: string,
    password: string
  ) => {
    try {
      isWaitingForResponse.value = true;
      console.log("Tentando fazer login com email e senha");
      // Tenta fazer o login com e-mail e senha
      const userCredential = await auth.signInWithEmailAndPassword(
        cfgAuth,
        email,
        password
      );
      const user = userCredential.user;
      console.log("Usuário autenticado com sucesso:", user);
      // Continue o fluxo da aplicação aqui
    } catch (error: any) {
      // Verifica o tipo de erro retornado
      console.log("Erro ao fazer login com email e senha:", error);
      if (error.code === "auth/wrong-password") {
        console.error("Senha incorreta:", error.message);
        showMessage(`Senha incorreta`,"error");
        // Trate erros de senha incorreta aqui
        // pode ser que exista conta google associada, então pode linkar ambas
      }
      if (error.code === 'auth/account-exists-with-different-credential') {
        // Tratamento quando a conta existe com um provedor diferente
        console.error('Conta já existe com credenciais diferentes.');
        showMessage(`Conta já existe com credenciais diferentes.`,"error");
      } else if (error.code === 'auth/popup-blocked') {
        // Tratamento quando o popup é bloqueado
        console.error('O popup foi bloqueado pelo navegador.');
        showMessage(`O popup foi bloqueado pelo navegador.`,"error");
      } 
      if (error.code === "auth/user-not-found") {
        try {
          // Se o usuário não for encontrado, cria um novo
          const newUserCredential = await auth.createUserWithEmailAndPassword(
            cfgAuth,
            email,
            password
          );
          const newUser = newUserCredential.user;
          console.log("Novo usuário criado e autenticado:", newUser);
          // Continue o fluxo da aplicação aqui para novo usuário
          await auth.sendEmailVerification(newUser);
          console.log("Email de verificação enviado para:", newUser.email);
        } catch (createError: any) {
          console.error("Erro ao criar novo usuário:", createError.message);
          showMessage(`Erro ao criar novo usuário`,"error");
          // Trate erros de criação de usuário aqui, se necessário
        }
      } else {
        // Outros erros de autenticação (por exemplo, senha incorreta)
        console.error("Erro ao fazer login com email e senha:", error.message);
        showMessage(`Erro ao fazer login com email e senha`,"error");
      }
    }
    finally {
      isWaitingForResponse.value = false;
    }
  };



  const registerUser = async (
    nome: string,
    telefone: string,
    idEmpresa: string
  ) => {
    const user = cfgAuth.currentUser;

    if (!user) {
      console.log("Nenhum usuário autenticado encontrado.");
      return;
    }
    isWaitingForResponse.value = true;
    try {
      // Atualizar o nome de exibição
      if (nome) {
        await auth.updateProfile(user, {
          displayName: nome,
        });

        const dataJson = {
          uid: user.uid,
          nome: nome,
          telefone: telefone,
          id_empresa: idEmpresa,
          descricao_cargo: "Consultor de vendas",
          email_verificado: user.emailVerified,
          email: user.email,
        };
        console.log("dataJson:", dataJson);
        const usuario = await postUsuario(dataJson);
        if (usuario.uid) {
          console.log("Cadastrado:", nome, " Usuario:", usuario);
          handleUserRegistered(usuario);
        }
      }
    } catch (error: any) {
      console.error("Erro Cadastro:", error.message);
      isUserRegistered.value = false;
    }
    finally {
      isWaitingForResponse.value = false;
    }
  };

  const sendPasswordReset = async (email: string) => {
    isWaitingForResponse.value = true;
    try {
        await auth.sendPasswordResetEmail(cfgAuth, email);
        showMessage(`Email de recuperação de senha enviado para: ${email}`);
        // Informe ao usuário que o email de recuperação foi enviado
    } catch (error: any) {
        
        showMessage(`Erro ao enviar email de recuperação de senha.`);
        // Trate o erro de envio do email de recuperação aqui
    }
    finally
    {
        isWaitingForResponse.value = false;
    }
  };
  

  const signOut = async () => {
    isWaitingForResponse.value = true;
    try {
      await auth.signOut(cfgAuth);
      resetControlSession();
      localStorage.removeItem('userData');
      console.log("Usuário deslogado com sucesso.");
    } catch (error: any) {
      console.error("Erro ao fazer logout:", error.message);
      showMessage("Erro ao fazer logout", "error");
    }
    finally {
      isWaitingForResponse.value = false;
    }
  };


  return {
    firebaseUser,
    registeredUser,
    isUserLoggedIn,
    isUserRegistered,
    isUserApproved,
    isUserAdmin,
    isWaitingForResponse,
    isWaitingVerification,

    signInWithEmailAndPassword,
    signInWithGoogle,
    signInWithFacebook,
    signOut,
    registerUser,
    sendPasswordReset,
  };
}
