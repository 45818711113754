// src/composables/useMsg.ts
import { ref } from 'vue';
import { NotificationProps } from '@/types/notification';

// Variáveis reativas definidas fora da função do composable
const queue = ref<Omit<NotificationProps, 'show'>[]>([]);
const activeNotification = ref<Omit<NotificationProps, 'show'> | null>(null);

function _wait(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function processQueue() {
  if (activeNotification.value || !queue.value.length) return;

  activeNotification.value = queue.value.shift() as Omit<NotificationProps, 'show'>;
  const displayDuration = activeNotification.value.timeout ?? 3000;
  await _wait(displayDuration);
  activeNotification.value = null;
  processQueue();
}

export function useMsg() {
  function showMessage(message: string, color: string = 'success', timeout?: number) {
    queue.value.push({ message, color, timeout });
    processQueue();
  }

  function closeNotification() {
    activeNotification.value = null;
  }

  return {
    activeNotification,
    showMessage,
    closeNotification
  };
}
