import axios from 'axios';
import { useApp } from '@/composables/useApp';
import { API_BASE_URL } from '@/cfg/urls';
const { setLoading } = useApp();


const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

axiosInstance.interceptors.request.use((config) => {
  setLoading(true);
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    setLoading(false);
    return response;
  },
  (error) => {
    setLoading(false);
    return Promise.reject(error);
  }
);

export default axiosInstance;
