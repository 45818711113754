import { Directive } from 'vue';

const elements: HTMLElement[] = [];



const parseOptions = function(options: string | undefined | null): { delay: string; duration: string; name: string } {
    if (typeof options === 'string') {
      const parsedOptions = JSON.parse(options.replace(/'/g, '"').replace(';', ''));
      return {
        delay: parsedOptions.delay || '.5s',
        duration: parsedOptions.duration || '1.2s',
        name: parsedOptions.name || 'fadeIn',
      };
    }
    return { delay: '.5s', duration: '1.2s', name: 'fadeIn' };
  };
  


  function addCss(item: HTMLElement, visibleClass = 'show-content') {
    let delay = ".5s";
    let duration = "1.2s";
    let keyframes = "fadeIn";

    if ( item.getAttribute( 'data-animation-options' ) ) {
        let options = parseOptions( item.getAttribute( 'data-animation-options' ) );
        delay = options.delay ? options.delay : ".5s";
        duration = options.duration ? options.duration : "1.2s";
        keyframes = options.name ? options.name : 'fadeIn';
    }

    let itemStyle = window.getComputedStyle( item );
    let tProperty = itemStyle.getPropertyValue( 'transition-property' ),
        tDelay = itemStyle.getPropertyValue( 'transition-delay' ),
        tDuration = itemStyle.getPropertyValue( 'transition-duration' );

    item.classList.add( keyframes, visibleClass );
    item.style.animationDelay = delay;
    item.style.animationDuration = duration;
    item.style.transitionDuration = duration;
    item.style.transitionDelay = delay;
    item.style.transitionProperty = 'visibility, opacity';

    const delays = parseInt(delay.substring(0, delay.length - 1)) * 1000;

    setTimeout( () => {
        item.style.transitionProperty = tProperty;
        item.style.transitionDelay = tDelay;
        item.style.transitionDuration = tDuration;
    }, delays + 1500 );
}

function removeCss(item: HTMLElement) {
    let keyframes = "fadeIn";
    if ( item.getAttribute( 'data-animation-options' ) ) {
        keyframes = parseOptions( item.getAttribute( 'data-animation-options' ) ).name;
        if ( !keyframes ) keyframes = 'fadeIn';
    }

    item.removeAttribute( 'style' );
    item.classList.remove( keyframes, 'show-content' );
}

function addNormalCss() {
    for ( let i = 0; i < elements.length; i++ ) {
        let element = elements[ i ];

        let top = element.getBoundingClientRect().top;
        let bottom = element.getBoundingClientRect().bottom;

        if ( !element.classList.contains( 'appear-animation-visible' ) )
            if ( ( top > 0 && window.innerHeight > top ) || ( bottom > 0 && top < 0 ) ) {
                addCss( element, 'appear-animation-visible' );
            }
    }
}

function addSlideCss(slideEl: Element) {
    const animateNodes = slideEl.querySelectorAll('.slide-animate');
    animateNodes.forEach((node: Element) => {
        if (node instanceof HTMLElement) {
            addCss(node);
        }
    });
}

function removeSlideCss( slideEl: Element ) {
    const animateNodes = slideEl.querySelectorAll('.slide-animate');
    animateNodes.forEach((node: Element) => {
        if (node instanceof HTMLElement) {
            removeCss(node);
        }
    });
}


const animateDirective: Directive<HTMLElement, any> = {
    mounted(el, binding, vnode) {
        if (el.classList.contains('animation-slider')) {
            // Assuming vnode has a compatible interface with your swiper instance
            const activeIndex = (vnode.component?.proxy as any)?.mySwiper?.activeIndex;

            addSlideCss(el.querySelectorAll('.swiper-slide')[activeIndex]);

            (vnode.component?.proxy as any)?.mySwiper?.on('transitionEnd', () => {
                const newIndex = (vnode.component?.proxy as any)?.mySwiper?.activeIndex;
                addSlideCss(el.querySelectorAll('.swiper-slide')[newIndex]);

                if (el.querySelectorAll('.swiper-slide')[newIndex - 1]) {
                    removeSlideCss(el.querySelectorAll('.swiper-slide')[newIndex - 1]);
                }
                if (el.querySelectorAll('.swiper-slide')[newIndex + 1]) {
                    removeSlideCss(el.querySelectorAll('.swiper-slide')[newIndex + 1]);
                }
            });
        } else if (!el.classList.contains('appear-animation-visible') && !el.closest('.animation-slider')) {
            if (el.getBoundingClientRect().top >= 0 && window.innerHeight > el.getBoundingClientRect().top) {
                addCss(el, 'appear-animation-visible');
            } else {
                elements.push(el);
                window.addEventListener('scroll', addNormalCss, { passive: true });
            }
        }
    },
    unmounted() {
        window.removeEventListener('scroll', addNormalCss); // Removed the `{ passive: true }` object
    }
};

export default animateDirective;