import { ref } from "vue";

const urlStorage = "https://storage.googleapis.com/central-re.appspot.com";
const urlApi = "https://server.central.re/api";
const cacheName = "resource-cache";
const userId = ref<number>(0);

// Definindo o estado com ref ou reactive
const estrictaBoldFont = ref<Uint8Array | null>(null);
const companyLogoSvg = ref<string | null>(null);
const yamahaRevsLogoSvg = ref<string | null>(null);
const kandoLogoSvg = ref<string | null>(null);
const installmentFrameSvg = ref<string | null>(null);
const footerCombinedLogosSvg = ref<string | null>(null);
const yWatermarkSvg = ref<string | null>(null);
const bankLogoSvg = ref<string | null>(null);
const backgroundLightPng = ref<Uint8Array | null>(null);

// Recurso temporário
const yamahaJson = ref<any | null>(null);

export function useData() {
  // Função para carregar recursos
  const loadResources = async (id: number) => {
    userId.value = id
    estrictaBoldFont.value = await loadResource(
      `${urlStorage}/resources/fonts/estricta-bold.ttf`,
      "bytes",
      false
    );
    companyLogoSvg.value = await loadResource(
      `${urlStorage}/resources/id/casamoto/logo.svg`,
      "text",
      false
    );
    yamahaRevsLogoSvg.value = await loadResource(
      `${urlStorage}/resources/svg/yamaha-revs-logo.svg`,
      "text",
      false
    );
    kandoLogoSvg.value = await loadResource(
      `${urlStorage}/resources/svg/kando-logo.svg`,
      "text",
      false
    );
    installmentFrameSvg.value = await loadResource(
      `${urlStorage}/resources/svg/frame.svg`,
      "text",
      false
    );
    footerCombinedLogosSvg.value = await loadResource(
      `${urlStorage}/resources/svg/footer-combined-logos.svg`,
      "text",
      false
    );
    yWatermarkSvg.value = await loadResource(
      `${urlStorage}/resources/svg/y-watermark.svg`,
      "text",
      false
    );
    bankLogoSvg.value = await loadResource(
      `${urlStorage}/resources/svg/bank-logo.svg`,
      "text",
      false
    );
    backgroundLightPng.value = await loadResource(
      `${urlStorage}/resources/img/background-light.png`,
      "bytes",
      false
    );

    yamahaJson.value = await loadResource(
      `${urlApi}/yamaha/${id}`,
      "json",
      true
    );
  };

  // Função para resetar recursos
  const resetResources = async () => {
    // Limpar o cache
    await caches.delete(cacheName);

    // Resetar estado
    estrictaBoldFont.value = null;
    companyLogoSvg.value = null;
    yamahaRevsLogoSvg.value = null;
    kandoLogoSvg.value = null;
    installmentFrameSvg.value = null;
    footerCombinedLogosSvg.value = null;
    yWatermarkSvg.value = null;
    bankLogoSvg.value = null;
    backgroundLightPng.value = null;
    yamahaJson.value = null;

    // Recarregar todos os recursos
    if(userId.value > 0) {
        await loadResources(userId.value);
    }
    
  };

  // Função para carregar um recurso
  const loadResource = async (
    path: string,
    type: "text" | "json" | "blob" | "bytes" | "stream",
    isTemporary: boolean
  ): Promise<any> => {
    if (isTemporary) {
      // Se for temporário, sempre busque da web
      return fetchResource(path, type);
    }

    // Verifique se o recurso está no cache
    const cachedResponse = await getCachedResponse(path);
    if (cachedResponse) {
      return processCachedResponse(cachedResponse, type);
    }

    // Se não estiver no cache, busque da web e armazene no cache
    const resource = await fetchResource(path, type);
    await cacheResponse(path, resource.response);
    return resource.data;
  };

  // Função para buscar o recurso
  const fetchResource = async (
    path: string,
    type: "text" | "json" | "blob" | "bytes" | "stream"
  ): Promise<{ data: any; response: Response }> => {
    const response = await fetch(path);
    const responseClone = response.clone(); // Clone antes de consumir

    let data;
    switch (type) {
      case "text":
        data = await response.text();
        break;
      case "json":
        data = await response.json();
        break;
      case "blob":
        data = await response.blob();
        break;
      case "bytes": {
        const arrayBuffer = await response.arrayBuffer();
        data = new Uint8Array(arrayBuffer);
        break;
      }
      case "stream":
        data = response.body; // Retorna o stream diretamente
        break;
    }

    return { data, response: responseClone };
  };

  // Função para processar a resposta do cache
  const processCachedResponse = async (
    response: Response,
    type: "text" | "json" | "blob" | "bytes" | "stream"
  ): Promise<any> => {
    switch (type) {
      case "text":
        return await response.text();
      case "json":
        return await response.json();
      case "blob":
        return await response.blob();
      case "bytes": {
        const arrayBuffer = await response.arrayBuffer();
        return new Uint8Array(arrayBuffer);
      }
      case "stream":
        return response.body; // Retorna o stream diretamente
    }
  };

  // Função para obter resposta do cache
  const getCachedResponse = async (
    path: string
  ): Promise<Response | undefined> => {
    const cache = await caches.open(cacheName);
    return await cache.match(path);
  };

  // Função para armazenar a resposta no cache
  const cacheResponse = async (path: string, response: Response) => {
    const cache = await caches.open(cacheName);
    await cache.put(path, response);
  };

  // Retorno dos estados e métodos
  return {
    estrictaBoldFont,
    companyLogoSvg,
    yamahaRevsLogoSvg,
    kandoLogoSvg,
    installmentFrameSvg,
    footerCombinedLogosSvg,
    yWatermarkSvg,
    bankLogoSvg,
    backgroundLightPng,
    yamahaJson,

    loadResources,
    resetResources,
  };
}
