// firebaseConfig.ts
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAvNZh9teexL2ccy6tZ1kZvRQY9BVdL6cY",
    authDomain: "central-re.firebaseapp.com",
    projectId: "central-re",
    storageBucket: "central-re.appspot.com",
    messagingSenderId: "2904659360",
    appId: "1:2904659360:web:ced9a4a5ac2d90f44980c5",
    measurementId: "G-LVRX35YNSZ"
};

// Inicializa o app Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Exporta a instância de autenticação
const auth = getAuth(firebaseApp);

export { firebaseApp, auth };