import { RouteRecordRaw } from "vue-router";

export function useCrudRoutes(
  modelName: string,
  listComponent: any,
  formComponent: any
): RouteRecordRaw[] {
  return [
    {
      path: `${modelName.toLowerCase()}`,
      name: `${modelName}List`,
      component: listComponent,
    },
    {
      path: `${modelName.toLowerCase()}/criar`,
      name: `${modelName}Create`,
      component: formComponent,
    },
    {
      path: `${modelName.toLowerCase()}/editar/:id`,
      name: `${modelName}Edit`,
      component: formComponent,
      props: true,
    },
  ];
}
