/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import { loadFonts } from './webfontloader'
import vuetify from './vuetify'
import { createPinia } from 'pinia'
import VAnimateCss from '@ossph/v-animate-css';


import './axios';
// Types
import type { App } from 'vue'
const pinia = createPinia()
import router from '../router'

export function registerPlugins (app: App) {
  loadFonts()
  app
    .use(vuetify)
    .use(VAnimateCss)
    .use(pinia)
    .use(router)
}
