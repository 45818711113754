/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Components
import App from "./App.vue";

// Composables
import { createApp } from "vue";
import "@fontsource/poppins";
import "@fontsource/poppins/100.css"; // Specify weight
import "@fontsource/poppins/200.css"; // Specify weight
import "@fontsource/poppins/300.css"; // Specify weight
import "@fontsource/poppins/400.css"; // Specify weight
import "@fontsource/poppins/500.css"; // Specify weight
import "@fontsource/poppins/600.css"; // Specify weight
import "@fontsource/poppins/700.css"; // Specify weight
import "@fontsource/poppins/800.css"; // Specify weight
import "@/styles/animation.scss";
import animateDirective from "./animateDiretive";

// Plugins
import { registerPlugins } from "@/plugins";
import { VueMaskDirective } from "v-mask";

declare global {
  interface Window {
    fbAsyncInit: () => void;
  }
}

window.fbAsyncInit = function () {
  FB.init({
    appId: "1567921897128563", // Substitua pelo seu App ID do Facebook
    cookie: true, // Habilitar cookies para permitir que o servidor acesse a sessão
    xfbml: true, // Analisa os plugins sociais da página
    version: "v14.0", // Use a versão mais recente do SDK
  });

  FB.AppEvents.logPageView();
};

const app = createApp(App);

const vMaskV2 = VueMaskDirective;

app.directive("mask", {
  beforeMount: vMaskV2.bind,
  updated: vMaskV2.componentUpdated,
  unmounted: vMaskV2.unbind,
});

app.directive("animate", animateDirective);

registerPlugins(app);

app.mount("#app");
