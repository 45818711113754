import axiosInstance from '@/plugins/axios';

export const useApi = (() => {
  const headersList = {
    "Accept": "*/*"
  }

  const getUsuarioByUid = async (uid: string) => {
    try {
      const response = await axiosInstance.get(`/usuario/${uid}`, { headers: headersList });
      return response.data;
    }
    catch (error) {
      console.log(error);
    }
    return null;
  }

  const postUsuario = async (usuario: any) => {
    try {
      const response = await axiosInstance.post(`/usuario`, usuario, {
        headers: {
          'Content-Type': 'application/json',
          ...headersList
        }
      });
      return response.data;
    }
    catch (error) {
      console.log(error);
    }
  }

  const deleteUsuario = async (id: string) => {
    try {
      const response = await axiosInstance.delete(`/usuario/${id}`, { headers: headersList });
      return response.data;
    }
    catch (error) {
      console.log(error);
    }
  }

  const putUsuario = async (usuario: any) => {
    try {
      const response = await axiosInstance.put(`/usuario/${usuario.id}`, usuario, {
        headers: {
          'Content-Type': 'application/json',
          ...headersList
        }
      });
      return response.data;
    }
    catch (error) {
      console.log(error);
    }
  }

  const getEmpresas = async () => {
    try {
      const response = await axiosInstance.get(`/empresas`, { headers: headersList });
      return response.data;
    }
    catch (error) {
      console.log(error);
    }
    return null;
  }

  const getUsuarios = async () => {
    try {
      const response = await axiosInstance.get(`/usuarios`, { headers: headersList });
      return response.data;
    }
    catch (error) {
      console.log(error);
    }
    return null;
  }

  return {
    getUsuarioByUid,
    postUsuario,
    putUsuario,
    deleteUsuario,
    getEmpresas,
    getUsuarios
  }
});