import { ref } from "vue";
import { useData } from "@/composables/useData";
import _ from 'lodash'

const { yamahaJson } = useData();


const moto = ref<any | null>(null);
const motos = ref<any[]>([]);

const initializeMotoData = async () => {
  while (!yamahaJson.value) {
    await new Promise(resolve => setTimeout(resolve, 1000));
  }
  motos.value = _.cloneDeep(yamahaJson.value.data);
  console.log(motos.value);
};

export function useMoto() {

  return {
    moto,
    motos,
    initializeMotoData,
  };
}
