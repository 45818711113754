export default {
  dark: {
    background: '#05090c',
    surface: '#131313',
    primary: '#2196F3',
    secondary: '#829099',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107'
  },
  light: {
    background: '#ffffff',
    surface: '#f2f5f8',
    primary: '#2196F3',
    secondary: '#BBDEFB',
    accent: '#29B6F6',
    error: '#ef476f',
    info: '#2962FF',
    success: '#06d6a0',
    warning: '#ffd166'
  }
}
